import React, { useState } from "react"
import "./style.scss"
import AutomationIMG from "../../../../../assets/img/alvo.jpg"
import { Button, Grow } from "@material-ui/core"
import VizSensor from "react-visibility-sensor"
import StarsIcon from "@mui/icons-material/Stars"
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto"
import AutoGraphIcon from "@mui/icons-material/AutoGraph"

const UniprocessWhyPage = () => {
  let [active, setActive] = useState(false)

  return (
    <div className="why-page">
      <h1 className="why-title">Por Que?</h1>
      <p className="why-subtitle">Entenda nossas motivações</p>
      <div className="why-content-wrapper">
        <Grow
          in={active}
          style={{ transformOrigin: "0 0 0" }}
          {...(active ? { timeout: 1000 } : {})}
        >
          <div className="why-image">
            <img src={AutomationIMG} alt="automação" />
          </div>
        </Grow>

        <div className="why-description">
          <VizSensor
            onChange={isVisible => {
              setActive(isVisible)
            }}
          >
            <div className="why-content-title">Automation Office</div>
          </VizSensor>
          <div className="why-content-subtitle">Mas por que?</div>
          <div className="why-text">
            Quando há tantas abordagens para automação de processos quanto há
            empresas, por que procurar uma ferramenta de automação de processos
            como UniProcess?
          </div>
          <div className="why-icons">
            <div className="why-icon">
              <StarsIcon />
              <div className="icon-title">Porque</div>
              <div className="icon-text">
                Porque acreditamos que as pessoas e o relacionamento humano é o
                que faz prosperar qualquer modelo de negócio, equipes e
                projetos. E que a tecnologia é tão somente o meio para nos
                ajudar a alcançar nossos propósitos.
              </div>
            </div>
            <div className="why-icon">
              <AssistantPhotoIcon />
              <div className="icon-title">Como</div>
              <div className="icon-text">
                Com pessoas incríveis que acreditam que podem ajudar outras
                pessoas a transformarem grandes ideias em realidade, por meio de
                tecnologias exponenciais a um custo baixo, impactando o mercado
                e fomentando o crescimento dos negócios e a empregabilidade das
                pessoas.
              </div>
            </div>
            <div className="why-icon">
              <AutoGraphIcon />
              <div className="icon-title">O Que</div>
              <div className="icon-text">
                O UniProcess é a via que permite uma jornada de hiperautomação
                mais abrangente, potencializando e conectando facilmente
                diversas tecnologias como RPA, I.A., Machine Learning, Motores
                de Regras, Sistemas Legado e as pessoas de forma simples,
                inteligente e eficiente.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UniprocessWhyPage
